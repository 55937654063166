<template>
  <div>
    <b-modal
      id="modal-order"
      ref="modal-order"
      size="xl"
      :title="getTitle()"
      :ok-disabled="$v.$invalid || isLoadingModal"
      :ok-title="modalName === 'add' ? $t('table.add') : $t('table.save')"
      :cancel-title="$t('table.cancel')"
      :hide-footer="!canEdit"
      @ok.prevent="showModalOrderConfirm"
    >
      <SpinnerLoader v-if="isLoadingModal" :loading="isLoadingModal === true ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidAddEditThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <div class="row w-100 mb-2">
          <div class="col-6">
            <label>{{ $t('table.agency') }}:</label>
            {{ selects.agency ? selects.agency.name : '' }}
          </div>
          <div class="col-6 pl-4">
            <label>{{ $t('table.project') }}:</label>
            {{ projectName }}
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <b-form-group style="width: 49%" :label="$t('table.orderName')" label-for="input-2-order-name">
            <b-form-input
              id="input-2-order-name"
              v-model="selects.orderName"
              type="text"
              :placeholder="$t('table.orderName')"
              required
              autofocus
              :disabled="(modalName === 'edit' && selects.close) || !canEdit"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <b-form-group style="width: 49%" :label="$t('table.selectType')">
            <MultiSelect
              v-model="selects.type"
              :options="placementTypeWithoutAll"
              :placeholder="$t('table.selectType')"
              label="name"
              track-by="id"
              data-automatic="ms-placement-type"
              :disabled="hasMediaPlans || (modalName === 'edit' && selects.close) || !canEdit"
            ></MultiSelect>
          </b-form-group>
          <b-form-group style="width: 49%" :label="$t('table.selectCommercialType')">
            <MultiSelect
              v-model="selects.commercial"
              :options="commercialTypeWithoutAll"
              :placeholder="$t('table.selectCommercialType')"
              label="name"
              track-by="id"
              data-automatic="ms-commercial-type"
              :disabled="hasMediaPlans || (modalName === 'edit' && selects.close) || !canEdit"
            ></MultiSelect>
          </b-form-group>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <b-form-group style="width: 49%" :label="$t('table.writeDateFrom')" label-for="datepicker-buttons-from">
            <datepicker-wrapper
              id="datepicker-buttons-from"
              v-model="selects.start"
              required
              :min="minDate"
              :max="maxDate"
              :disabled="hasMediaPlans || (modalName === 'edit' && selects.close) || !canEdit"
            />
          </b-form-group>
          <b-form-group style="width: 49%" :label="$t('table.writeDateTo')" label-for="datepicker-buttons-to">
            <datepicker-wrapper
              id="datepicker-buttons-to"
              v-model="selects.end"
              required
              :min="getMaxDate(selects.start, minDate)"
              :max="maxDate"
              :disabled="hasMediaPlans || (modalName === 'edit' && selects.close) || !canEdit || !selects.start"
            />
          </b-form-group>
        </div>

        <div class="d-flex align-items-center mb-3">
          <currency-input
            v-model="selects.budget"
            style="width: 49%"
            class="mr-4"
            :placeholder="$t('table.budget')"
            data-automatic="input-budget"
            :disabled="(modalName === 'edit' && selects.close) || !canEdit"
          />
          <b-form-checkbox
            id="checkbox-taxes"
            v-model="selects.taxes"
            name="checkbox-taxes"
            class="custom-control-inline"
            :disabled="!canEdit || (modalName === 'edit' && selects.close)"
          >
            {{ $t('table.taxes') }}
          </b-form-checkbox>
          <b-form-checkbox id="checkbox-close" v-model="selects.close" name="checkbox-close" class="custom-control-inline ml-1" :disabled="!canEdit">
            {{ $t('table.close') }}
          </b-form-checkbox>
          <b-button
            v-if="canViewDiscounts"
            :disabled="!(isSelectedAllChannels && selects.start && selects.end)"
            :title="!(isSelectedAllChannels && selects.start && selects.end) ? $t('table.selectChannelsAndDatesFirst') : ''"
            data-automatic="discounts-btn"
            @click="isOpenModalDiscounts = true"
          >
            {{ $t('table.discounts') }}
          </b-button>
        </div>

        <hr />
        <div class="d-flex justify-content-end align-items-center pb-2">
          <b-button v-if="canEdit" size="sm" data-automatic="add-row-btn" :disabled="(modalName === 'edit' && selects.close) || !canEdit" @click="addRow">
            {{ $t('table.add') }}
          </b-button>
        </div>
        <div class="d-flex wrapper-table-project-settings gap-3">
          <label style="width: 35%">{{ $t('channelModal.channel') }}</label>
          <label style="width: 30%">{{ $t('channelModal.measurements') }}</label>
          <label style="width: 35%">{{ $t('channelModal.target') }}</label>
        </div>
        <div v-for="(sel, index) in selects.orderSettings" :key="index" class="d-flex wrapper-table-project-settings gap-3">
          <b-form-group :id="`input-group-${index}-${index}`" style="width: 35%">
            <MultiSelect
              v-model="selects.orderSettings[index].channel"
              :options="channelList"
              :placeholder="$t('table.selectChannel')"
              label="name"
              track-by="id"
              :disabled="(channelAndTaExist(sel.channel, sel.target) && !!sel.id) || (modalName === 'edit' && selects.close) || !canEdit"
              data-automatic="ms-os-channel"
            ></MultiSelect>
          </b-form-group>
          <b-form-group style="width: 30%">
            <MultiSelect
              v-model="selects.orderSettings[index].measurement"
              class="mb-2"
              :options="measurementsList || []"
              :placeholder="$t('channelModal.measurements')"
              label="name"
              track-by="id"
              :disabled="(channelAndTaExist(sel.channel, sel.target) && !!sel.id) || (modalName === 'edit' && selects.close) || !canEdit"
              data-automatic="ms-os-measurement"
              @input="loadTargetList(index)"
            ></MultiSelect>
          </b-form-group>
          <b-form-group style="width: 30%">
            <MultiSelect
              v-model="selects.orderSettings[index].target"
              class="mb-2"
              :options="selects.orderSettings[index].targetList || []"
              :placeholder="$t('channelModal.target')"
              label="name"
              track-by="id"
              :disabled="(channelAndTaExist(sel.channel, sel.target) && !!sel.id) || (modalName === 'edit' && selects.close) || !canEdit"
              data-automatic="ms-os-target"
            ></MultiSelect>
          </b-form-group>

          <div class="tb tb-m" style="text-align: center; flex-basis: 5%" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">
            <b-icon
              v-if="
                ((canEdit && !sel.id) || (modalName === 'edit' && !selects.close && !channelAndTaExist(sel.channel, sel.target))) &&
                selects.orderSettings.length > 1
              "
              class="trash-hover cursor-pointer"
              icon="trash"
              :title="$t('table.delete')"
              @click="deleteRow(index)"
            ></b-icon>
          </div>
        </div>

        <hr />
        <div>
          <VipSettings
            v-if="FEATURES.VIP && canReadVIP"
            :disabled="!canEdit || !isVip || !canEditVIP"
            :is-vip.sync="selects.is_vip"
            :vip-dates.sync="selects.vip_dates"
            :min-date="selects.start ?? minDate"
            :max-date="selects.end ?? maxDate"
          ></VipSettings>
        </div>
      </form>
    </b-modal>

    <!-- CONFIRM -->
    <b-modal
      ref="modal-order-confirm"
      size="sm"
      :title="modalName === 'add' ? $t('table.addOrder') : $t('table.editOrder')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="Order"
    >
      {{ modalName === 'add' ? $t('table.addOrder') : $t('table.editOrder') }} {{ selects.orderName }}?
    </b-modal>

    <!-- DELETE ORDER MODAL -->
    <b-modal
      ref="delete-order-confirm"
      size="sm"
      :title="$t('table.deleteOrders')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="deleteOrders"
    >
      <p class="my-2">
        {{ $t('table.deleteOrders') }} <span class="text-danger">{{ modalCurrentOrder ? modalCurrentOrder.name : '' }}</span>
        ?
      </p>
    </b-modal>

    <ModalDiscounts
      modal-name="order"
      :discounts-edit-list="discountsEditList"
      :modal-discounts-type="modalName"
      :is-open-modal="isOpenModalDiscounts"
      :can-edit="modalName === 'edit' && selects.close ? false : canEditDiscounts"
      :can-delete="modalName === 'edit' && selects.close ? false : canDeleteDiscounts"
      :can-add="modalName === 'edit' && selects.close ? false : canAddDiscounts"
      :channels_id_list="isSelectedAllChannels ? selects.orderSettings.map((el) => el.channel.id) : undefined"
      :date_from="selects.start ? selects.start : undefined"
      :date_to="selects.end ? selects.end : undefined"
      :project-discounts="projectDiscounts"
      @isOpenModal="isOpenModalDiscounts = false"
      @transferDiscounts="transferDiscounts"
      @refreshData="refreshData"
      @removeDiscount="removeDiscount"
    />
  </div>
</template>

<script>
import MultiSelect from '@/components/MultiSelect';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import { mapGetters } from 'vuex';
import ModalDiscounts from '@/components/Projects/ModalDiscounts';
import SpinnerLoader from '@/components/SpinnerLoader';
import CurrencyInput from '@/components/CurrencyInput';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import getMinOrMaxDate from '@/mixins/getMinOrMaxDate';
import VipSettings from '../Vip/VipSettings.vue';
import getVipDatesInRange from '@/utils/getVipSettingsInRange';

export default {
  name: 'ModalOrders',
  components: { MultiSelect, ModalDiscounts, SpinnerLoader, CurrencyInput, DatepickerWrapper, VipSettings },
  mixins: [getMinOrMaxDate],
  props: {
    isOpenModal: {
      type: String,
      default: undefined,
    },
    modalName: {
      type: String,
      default: 'add',
    },
    channelList: {
      required: true,
      type: Array,
      default: () => [],
    },
    modalCurrentOrder: {
      type: Object,
      default: undefined,
    },
    commercialTypeId: {
      required: true,
      type: Number,
    },
    placementTypeId: {
      required: true,
      type: Number,
    },
    agencyId: {
      required: true,
      type: Number,
    },
    projectName: {
      required: true,
      type: String,
    },
    hasMediaPlans: {
      required: true,
      type: Boolean,
    },
    minDate: {
      required: true,
      type: String,
    },
    maxDate: {
      required: true,
      type: String,
    },
    canEdit: {
      type: Boolean,
    },
    modalOrderChannelsAndTA: {
      type: Array,
      default: () => [],
    },
    projectDiscounts: {
      required: true,
      type: Array,
    },
    isVip: {
      type: Boolean,
      default: false,
    },
    vipDates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpenModalDiscounts: false,
      selects: {
        agency: '',
        taxes: false,
        close: false,
        budget: '',
        orderName: '',
        start: '',
        end: '',
        type: '',
        commercial: '',
        discounts: [],
        orderSettings: [
          {
            channel: '',
            target: '',
            measurement: '',
            targetList: [],
          },
        ],
        vip_dates: [],
        is_vip: false,
      },
      discountsEditList: [],
      isLoadingModal: false,
      isModalBusy: false,
    };
  },
  validations: {
    selects: {
      //budget: {required},
      orderName: { required },
      start: { required },
      end: { required },
      commercial: { required },
      type: { required },
      orderSettings: {
        required,
        $each: {
          channel: { required },
          target: { required },
          measurement: { required },
        },
      },
      vip_dates: {
        $each: {
          auction_coeff: { required, minValue: minValue(0), maxValue: maxValue(1) },
          date_from: { required },
          date_to: { required },
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      commercialType: 'getCommercialType',
      commercialTypeWithoutAll: 'getCommercialTypeWithoutAll',
      channelsList: 'getChannelsList',
      measurementsList: 'getMeasurementsList',
      targetList: 'getTargetList',
      placementTypeWithoutAll: 'getPlacementTypeWithoutAll',
      agenciesList: 'getAgenciesList',
    }),
    canReadVIP: function () {
      return this.$checkPermissions('vip.settings.read');
    },
    canEditVIP: function () {
      return this.$checkPermissions('vip.settings.edit');
    },
    canViewDiscounts: function () {
      return this.$checkPermissions('scope.pom_discount');
    },
    canAddDiscounts: function () {
      return this.$checkPermissions('discount.pom_update');
    },
    canEditDiscounts: function () {
      return this.$checkPermissions('discount.pom_update');
    },
    canDeleteDiscounts: function () {
      return this.$checkPermissions('discount.pom_update');
    },
    isSelectedAllChannels: function () {
      return this.selects.orderSettings.every((el) => el.channel);
    },
  },
  watch: {
    async isOpenModal() {
      await this.showModalOrder();
    },
    'selects.is_vip': {
      handler(v) {
        if (v == true) {
          if (this.modalName == 'add')
            this.selects.vip_dates = getVipDatesInRange(
              this.vipDates,
              this.getMaxDate(this.selects.start, this.minDate),
              this.getMinDate(this.selects.end, this.maxDate)
            );
        } else {
          this.selects.vip_dates = [];
        }
      },
      immediate: true,
    },
    'selects.end'(v) {
      if (this.modalName === 'add' && this.selects.is_vip) {
        this.selects.vip_dates = getVipDatesInRange(
          this.selects.vip_dates,
          this.getMaxDate(this.selects.start, this.minDate),
          this.getMinDate(this.selects.end, this.maxDate)
        );
      }
    },
    'selects.start'(v) {
      if (this.modalName === 'add' && this.selects.is_vip) {
        this.selects.vip_dates = getVipDatesInRange(
          this.selects.vip_dates,
          this.getMaxDate(this.selects.start, this.minDate),
          this.getMinDate(this.selects.end, this.maxDate)
        );
      }
    },
  },
  methods: {
    getTitle() {
      if (this.modalName === 'add') return this.$i18n.t('table.addOrder');
      else if (this.modalName === 'edit' && !this.canEdit) return this.selects.orderName;
      else return this.$i18n.t('table.editOrder');
    },
    async showModalOrder() {
      if (this.isOpenModal) {
        if (this.modalName !== 'delete') {
          this.isLoadingModal = true;
          this.$refs['modal-order'].show();
          this.clearFields();
          await Promise.all([
            this.channelsList.length < 1 ? this.$store.dispatch('GET_CHANNELS', { per_page: 1000 }) : undefined,
            this.measurementsList.length < 1 ? this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 }) : undefined,
            this.placementTypeWithoutAll.length < 1 ? this.$store.dispatch('GET_PLACEMENT_TYPE', { per_page: 1000 }) : undefined,
          ]);
          this.selects.agency = this.agenciesList.find((ag) => ag.id === this.agencyId);
          if (this.modalName === 'edit') {
            await this.$store.dispatch('GET_TARGET', { per_page: 1000 });

            this.selects.is_vip = this.modalCurrentOrder.is_vip ?? false;
            this.selects.vip_dates = this.modalCurrentOrder.vip_dates ?? [];

            this.selects.orderName = this.modalCurrentOrder.name;
            this.selects.taxes = this.modalCurrentOrder.taxes;
            this.selects.close = this.modalCurrentOrder.is_closed;
            const dirtyBudget = this.modalCurrentOrder.estimated_budget;
            this.selects.budget = dirtyBudget.replaceAll(' ', '');
            this.selects.start = this.modalCurrentOrder.date_from;
            this.selects.end = this.modalCurrentOrder.date_to;
            this.selects.commercial = this.commercialType.find((type) => type.id === this.modalCurrentOrder.commercial_type_id);
            this.selects.type = this.placementTypeWithoutAll.find((type) => type.id === this.modalCurrentOrder.placement_type_id);

            if (this.modalCurrentOrder.order_settings) {
              this.selects.orderSettings.splice(0, 1);
              for (let i in this.modalCurrentOrder.order_settings) {
                const ch = this.channelsList.find((channel) => channel.id === this.modalCurrentOrder.order_settings[+i].channel_id);
                if (ch) {
                  this.selects.orderSettings.push({
                    channel: ch,
                    measurement: this.measurementsList.find(
                      (el) =>
                        // get measurement by finding its id in target object
                        el.id ===
                        this.targetList.find((target) => target.id === this.modalCurrentOrder.order_settings[+i].target_audience_id)?.measurement_company_id
                    ),
                    target: this.targetList.find((target) => target.id === this.modalCurrentOrder.order_settings[+i].target_audience_id),
                    id: this.modalCurrentOrder.order_settings[+i].id,
                    orderId: this.modalCurrentOrder.order_settings[+i].order_id,
                  });
                }
              }
            }
            this.discountsEditList = this.modalCurrentOrder.discounts;
          } else {
            this.selects.commercial = this.commercialType.find((type) => type.id === this.commercialTypeId);
            this.selects.type = this.placementTypeWithoutAll.find((type) => type.id === this.placementTypeId);

            this.selects.is_vip = this.isVip;
            this.selects.vip_dates = this.vipDates;
          }
          this.isLoadingModal = false;
        } else {
          this.showModalDeleteOrders();
        }
        this.$emit('isOpenModal', null);
      }
    },
    hideModalOrder() {
      this.$refs['modal-order'].hide();
    },
    showModalOrderConfirm() {
      this.$refs['modal-order-confirm'].show();
    },
    hideModalOrderConfirm() {
      this.$refs['modal-order-confirm'].hide();
    },

    showModalDeleteOrders() {
      this.$refs['delete-order-confirm'].show();
    },
    hideModalDeleteOrdersConfirm() {
      this.$refs['delete-order-confirm'].hide();
    },

    transferDiscounts(data) {
      this.selects.discounts = data[0];
      this.discountsEditList = this.discountsEditList.filter((el) => el.id);
      this.discountsEditList.push(...data[0]);
    },

    refreshData() {
      this.$emit('refreshOrders', 'restore');
    },

    clearFields() {
      this.selects.agency = '';
      this.selects.taxes = false;
      this.selects.close = false;
      this.selects.budget = '';
      this.selects.orderName = '';
      this.selects.start = '';
      this.selects.end = '';
      this.selects.commercial = '';
      this.selects.type = '';
      this.selects.discounts = [];
      this.selects.orderSettings = [
        {
          channel: '',
          measurement: '',
          target: '',
          targetList: [],
        },
      ];
      this.discountsEditList = [];
      this.selects.is_vip = false;
      this.selects.vip_dates = [];
      this.selects.limit_advertiser_in_auction_block = 0;
    },

    addRow() {
      this.selects.orderSettings.push({
        channel: '',
        measurement: '',
        target: '',
        targetList: [],
      });
    },
    deleteRow(data) {
      this.selects.orderSettings.splice(data, 1);
    },

    async Order() {
      this.isModalBusy = true;
      let orderSettingsArray = [];
      let discountsList = [];
      this.selects.orderSettings.forEach((el) => {
        if (el.channel !== '' && el.target !== '') {
          orderSettingsArray.push({
            channel_id: el.channel.id,
            target_audience_id: el.target.id,
          });
        }
      });
      this.selects.discounts.forEach((el) => {
        if (!el.id && el.fromType === 'Order') {
          discountsList.push({
            discount_type_id: el.discount_type_id,
            from: el.from,
            to: el.to,
            percent: el.percent,
          });
        }
      });
      const formData = {
        project_id: this.modalCurrentOrder.project_id,
        placement_type_id: this.selects.type.id,
        commercial_type_id: this.selects.commercial.id,
        name: this.selects.orderName,
        taxes: this.selects.taxes,
        is_closed: this.selects.close,
        estimated_budget: +this.selects.budget,
        date_from: this.selects.start,
        date_to: this.selects.end,
        order_settings: orderSettingsArray,
        discounts: this.canEditDiscounts ? discountsList : undefined,
        is_vip: this.canEditVIP && this.FEATURES.VIP ? this.selects.is_vip : undefined,
        vip_dates: this.canEditVIP && this.FEATURES.VIP ? this.selects.vip_dates : undefined,
      };
      if (this.modalName === 'add') {
        await this.$store.dispatch('POST_ORDERS', {
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addOrders'),
              text: this.selects.orderName,
            });
            this.clearFields();
            this.hideModalOrder();
            this.$emit('refreshOrders', 'restore');
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      } else {
        const orderId = this.modalCurrentOrder.id;
        await this.$store.dispatch('PUT_ORDERS', {
          orderId,
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.editOrders'),
              text: this.selects.orderName,
            });
            this.clearFields();
            this.hideModalOrder();
            this.$emit('refreshOrders', 'restore');
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      }
      this.hideModalOrderConfirm();
      this.isModalBusy = false;
    },

    async deleteOrders() {
      this.isModalBusy = true;
      const formData = this.modalCurrentOrder.id;
      await this.$store.dispatch('DELETE_ORDERS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deleteOrders'),
            text: this.modalCurrentOrder.name,
          });
          this.$emit('refreshOrders', 'restore-project');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
          this.$emit('refreshOrders', 'restore');
        },
      });
      this.hideModalDeleteOrdersConfirm();
      this.isModalBusy = false;
    },

    checkIfValidAddEditThenEnter() {
      if (!this.$v.$invalid) this.showModalOrderConfirm();
    },

    async loadTargetList(index) {
      if (!this.isOpenModal) this.selects.orderSettings[index].target = ''; // Don't clear on edit modal load
      if (this.selects.orderSettings[index].measurement) {
        await this.$store.dispatch('GET_TARGET', {
          'filter[measurement_company_id]': this.selects.orderSettings[index].measurement.id,
        });
      }
      this.selects.orderSettings[index].targetList = this.targetList;
    },

    removeDiscount(id) {
      //remove discount from list after API delete
      this.discountsEditList = this.discountsEditList.filter((el) => el.id !== id);
    },

    // Check if props contain ID of MP channel and its TA (for disabling fields)
    channelAndTaExist(channel, target) {
      return this.modalOrderChannelsAndTA.find((e) => e.channel_id === channel?.id)?.values.includes(target?.id) || false;
    },
  },
};
</script>

<style scoped></style>
